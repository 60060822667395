import urlModule from 'url';
import { matchPath } from 'react-router';

import { type ThemeState, themeStringToObject } from '@atlaskit/tokens';

import type { PageProps, ParsedPageProps } from './PageProps';
import type { PageAllowedFeatures, ParsedPageAllowedFeatures } from '../features';
import { DEFAULT_ALLOWED_FEATURES } from '../features';
import { EMBEDDED_CONFLUENCE_MODE } from '../page-common/EmbeddedConfluenceMode';
import {
	EDIT_PAGE_EMBED,
	VIEW_BLOG,
	VIEW_BLOG_DATE_LEGACY,
	EDIT_PAGE_V2,
	VIEW_PAGE,
	namedRoutes,
} from '../page-common/routes';

export function parsePageProps(props: PageProps): ParsedPageProps {
	let {
		contentId = '',
		hostname,
		parentProduct = '',
		spaceKey,
		url,
		allowedFeatures,
		mode,
		draftShareId,
		hash,
		themeState,
		...passThroughProps
	} = props;

	const parsedAllowedFeatures = parseAllowedFeatures(allowedFeatures);

	if (!url) {
		return {
			contentId,
			hostname,
			parentProduct,
			spaceKey,
			protocol: 'https:',
			mode: mode ?? EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
			allowedFeatures: parsedAllowedFeatures,
			draftShareId,
			hash,
			themeState,
			...passThroughProps,
		};
	}

	const parsedProps = parseUrl(url);

	if (!parsedProps) {
		return {
			hostname,
			contentId,
			spaceKey,
			parentProduct,
			mode,
			protocol: 'https:',
			allowedFeatures: parsedAllowedFeatures,
			draftShareId,
			hash,
			themeState,
			...passThroughProps,
		};
	}
	const themeStateParsedObject = parsedProps.themeState
		? themeStringToObject(decodeURIComponent(parsedProps.themeState))
		: undefined;
	// If the props are explicity passed in along with URL, those passed-in props will be overriding the URL-extract props.
	contentId = contentId || parsedProps.contentId;
	parentProduct = parentProduct || parsedProps.parentProduct;
	hostname = hostname || parsedProps.hostname || window.location.hostname;
	spaceKey = spaceKey || parsedProps.spaceKey;
	draftShareId = draftShareId || parsedProps.draftShareId;
	hash = hash || parsedProps.hash;
	themeState = themeState || (themeStateParsedObject as ThemeState);

	let modeFromUrl;
	switch (parsedProps.route) {
		case VIEW_PAGE.name:
		case VIEW_BLOG.name:
		case VIEW_BLOG_DATE_LEGACY.name:
			modeFromUrl = EMBEDDED_CONFLUENCE_MODE.VIEW_MODE;
			break;
		case EDIT_PAGE_EMBED.name:
		case EDIT_PAGE_V2.name:
			modeFromUrl = EMBEDDED_CONFLUENCE_MODE.EDIT_MODE;
	}

	return {
		allowedFeatures: parseAllowedFeatures(allowedFeatures),
		contentId,
		draftShareId,
		hash,
		hostname,
		mode: mode || modeFromUrl,
		parentProduct,
		protocol: parsedProps.protocol,
		spaceKey,
		themeState,
		...passThroughProps,
	};
}

/**
 * @deprecated This is a temporary API function as ViewPage and EditPage components will
 * support url prop soon. See CBT-954 for more information
 */
export function parseUrl(url: string | undefined) {
	if (!url) {
		return null;
	}

	const matchedRoute = findAndMatchRoute(namedRoutes, url);

	if (!matchedRoute) {
		return null;
	}

	const { routeName, ...match } = matchedRoute;
	return {
		route: routeName,
		hostname: match.host,
		protocol: match.protocol,
		contentId: match.params.contentId || '',
		spaceKey: match.params.spaceKey || '',
		hash: match.hash,
		// EP currently supports only 1 parent product, no more than 1 parent product should be passed in
		parentProduct: Array.isArray(match.query.parentProduct)
			? match.query.parentProduct[0]
			: match.query.parentProduct || '',
		draftShareId: Array.isArray(match.query.draftShareId)
			? match.query.draftShareId[0]
			: match.query.draftShareId,
		themeState: match.query.themeState as string,
	};
}

function findAndMatchRoute(routes: typeof namedRoutes, url: string) {
	for (const routeName of Object.keys(routes)) {
		const pattern = routes[routeName].pattern;
		const match = matchUrl(pattern, url);
		if (match) {
			return { routeName, ...match };
		}
	}
}

type UrlMatch = {
	url: string;
	protocol: string;
	host: string;
	pathname: string;
	search: string;
	hash: string;
	pattern: string;
	params: { [key: string]: string };
	query: { [key: string]: string | string[] | undefined };
	themeState?: string;
};

function matchUrl(pattern: string, url: string): UrlMatch | null {
	const parsed = urlModule.parse(url, true);

	const pathname: string = parsed.pathname || '';

	let match = matchPath<{ [key: string]: string }>(pathname, {
		path: pattern,
		exact: true,
	});

	// some of our routes need query params to match
	if (!match) {
		match = matchPath(`${pathname}${parsed.search}`, {
			path: pattern,
			exact: true,
		});
	}

	if (!match) {
		return null;
	}

	// URI decode params
	const decodedParams: { [key: string]: string } = {};
	if (match && match.params) {
		for (const [key, value] of Object.entries(match.params)) {
			decodedParams[key] = value;
		}
	}

	return {
		url,
		protocol: parsed?.protocol || 'https:',
		host: parsed?.host || '',
		pathname,
		search: parsed.search || '',
		hash: parsed.hash || '',
		pattern,
		params: decodedParams,
		query: parsed.query || '',
	};
}

export function parseAllowedFeatures(
	allowedFeatures?: PageAllowedFeatures,
): ParsedPageAllowedFeatures {
	let parsedAllowedFeatures: ParsedPageAllowedFeatures = {
		view: allowedFeatures?.view,
		edit: allowedFeatures?.edit,
	};

	if (!allowedFeatures?.view) {
		parsedAllowedFeatures.view = [...DEFAULT_ALLOWED_FEATURES.view, 'edit'];
	} else if (Array.isArray(allowedFeatures?.view)) {
		parsedAllowedFeatures.view = [...allowedFeatures?.view, 'edit'];
	}

	return parsedAllowedFeatures;
}
