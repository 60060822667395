import gql from 'graphql-tag';

import { OperationTypes, canPerformContentOperation } from '@confluence/entity-operations';
import { getApolloClient } from '@confluence/graphql';
import { getSessionData } from '@confluence/session-data';

import { ViewPageEditorPreloadQuery } from './ViewPageEditorPreloadQuery.graphql';
import type { ViewPageEditorPreloadQuery as Payload } from './__types__/ViewPageEditorPreloadQuery';
import { HeuristicsCache } from './HeuristicsCache';

/**
 * Preload Heuristics
 *
 * Requirements for editor preloading occur using a series of evaluations
 * designed to weigh in user's tendency to edit as well as the server-side
 * cost of triggering the preloading of resources. Heuristics for preloading
 * the Editor ahead of the user's transition to the Edit Page are:
 *
 * 1. Whether or not the user has permission to edit the page
 * 2. Whether or not the user has contributed to the page
 * 3. Whether or not the page was updated in the past 30 days
 */
export class PreloadHeuristics extends HeuristicsCache {
	private static readonly oneDay = 1000 * 60 * 60 * 24;

	/**
	 * Passes Preload Heuristics
	 *
	 * Runs a series of heuristics checks to determine the user's propensity
	 * to edit the current page. Currently the heuristics take into account:
	 *
	 * 1. Whether or not the user has permission to edit the page
	 * 2. Whether or not the user has contributed to the page
	 * 3. Whether or not the page was updated in the past 30 days
	 */
	public static async passesPreloadingHeuristics(contentId: string) {
		const likelyEditor = await this.resolveTendencyToEdit(contentId);
		const { isEditPermitted } = this.resolveEditPermissions(contentId);
		return likelyEditor && isEditPermitted;
	}

	/**
	 * Resolve Edit Permissions
	 *
	 * Returns the edit permissions for a given contentId. Edit permissions
	 * include the `isEmbeddedEditor` boolean to allow for short-circuiting
	 * to NCS preloading
	 */
	public static resolveEditPermissions = this.withCache('permissionMap', (contentId: string) => {
		const data = getApolloClient().readQuery<Payload>({
			query: ViewPageEditorPreloadQuery,
			variables: { contentId },
		});
		if (data) {
			return {
				isEditPermitted: canPerformContentOperation({
					operationCheckResult: data,
					operation: OperationTypes.UPDATE,
				}),
				isEmbeddedEditor: data?.content?.nodes?.[0]?.metadata?.frontend?.embedded ?? false,
			};
		}
		return {
			isEditPermitted: false,
			isEmbeddedEditor: false,
		};
	});

	/**
	 * Resolve Tendency to Edit
	 *
	 * Returns true if the current user has contributed to the current
	 * page and the page has been updated in the past 30 days
	 */
	public static resolveTendencyToEdit = this.withCache('tendencyMap', async (contentId: string) => {
		const cache = this.Cache.get('tendencyMap');
		if (cache.has(contentId)) {
			return cache.get(contentId);
		}
		const hasContributed = await this.isContributor(contentId);
		if (!hasContributed) {
			return false;
		}
		const date = this.getTimeOfLastUpdate(contentId);
		const MSFromCreation = new Date().getTime() - date;
		return MSFromCreation / this.oneDay < 30;
	});

	/**
	 * Get Time of Last Update
	 *
	 * Returns the time that the last update was made to the page
	 */
	private static getTimeOfLastUpdate(contentId: string) {
		const lastUpdated = getApolloClient().readFragment<{
			when: string;
		}>({
			id: `$Content:${contentId}.history.lastUpdated`,
			fragment: gql`
				fragment When on Version {
					when
				}
			`,
		});
		if (lastUpdated) {
			return new Date(lastUpdated?.when).getTime();
		}
		return new Date().getTime() - this.oneDay * 31;
	}

	/**
	 * Is Contributor
	 *
	 * Returns true if the current user has contributed content
	 * to the current page
	 */
	private static async isContributor(contentId: string) {
		const userID = (await getSessionData()).userId;
		if (!userID) {
			return false;
		}
		const cache = getApolloClient().readFragment<{
			isCurrentUserContributor: boolean;
		}>({
			id: `$ROOT_QUERY.contentContributors({"id":"${contentId}","limit":4})`,
			fragment: gql`
				fragment Contributors on ContentContributors {
					isCurrentUserContributor
				}
			`,
		});
		return cache?.isCurrentUserContributor || false;
	}
}
