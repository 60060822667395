import {
	confluenceSessionStorageInstance as sessionStorage,
	keys,
} from '@confluence/storage-manager';
import { getMonitoringClient } from '@confluence/monitoring';
import {
	isPartOfElementStillInViewPort,
	isTopOfElementStillInViewPort,
	getElementTop,
} from '@confluence/dom-helpers';

import type {
	VisibleElements,
	VisibleColumnElement,
	ViewPositionData,
	ScrollOptions,
} from './types';
/**
 * Given a list of elements, returns a list of element which is still in viewport (one part of element is still in viewport).
 * @param {Array} els - list of elements
 */
export const findVisibleElements = (els?: HTMLCollection): VisibleElements[] => {
	const result: VisibleElements[] = [];

	// use `Array.some` to stop the loop sooner.
	if (els) {
		for (let index = 0; index < els.length; index++) {
			if (isPartOfElementStillInViewPort(els[index] as HTMLElement)) {
				result.push({
					targetElementIndex: index,
					visibleBlockEl: els[index] as HTMLElement,
				});
			}
		}
	}

	return result;
};

/**
 * Given a list of elements, returns a first element whose top is in viewport.
 * In the worst case, any visible element in viewport is returned alternatively.
 * @param els
 * @returns {*}
 */
export const findTargetElement = (els?: HTMLCollection): VisibleElements => {
	const visibleEls = findVisibleElements(els);
	let elHasTopVisible: VisibleElements | null = null;

	const foundVisible = visibleEls.some((item) => {
		if (isTopOfElementStillInViewPort(item.visibleBlockEl)) {
			elHasTopVisible = item;
			return true;
		}

		return false;
	});

	// in the worst case, return first element which is still in viewport
	if (!foundVisible && visibleEls.length > 0) {
		return visibleEls[0];
	}

	// a default value which indicates that no element is in viewport
	const noElementValue = {
		targetElementIndex: -1,
		visibleBlockEl: null,
	};

	return elHasTopVisible || noElementValue;
};

/**
 * Visible column is a column which contains any content that is still in viewport
 * @param {Array} columns - list of elements
 */
export const findVisibleColumn = (columns: HTMLCollection): VisibleColumnElement => {
	const result: VisibleColumnElement = {
		targetElementIndexInColumn: -1,
		columnIndex: -1,
		// visible block element in target column
		visibleBlockEl: null,
	};

	// usage of .some iterator without using its return value is considered a bug.
	for (let index = 0; index < columns.length; index++) {
		const { visibleBlockEl, targetElementIndex } = findTargetElement(columns[index].children);
		if (visibleBlockEl) {
			result.columnIndex = index;
			result.targetElementIndexInColumn = targetElementIndex;
			result.visibleBlockEl = visibleBlockEl;
			break;
		}
	}

	return result;
};

/**
 * Check page has column layout or not.
 * In case page has column layout, we need to save some extra inforamtion like column index and row index.
 */
export const hasColumnLayout = (elContainer: HTMLElement) => {
	if (!elContainer) {
		return false;
	}

	const firstChildEl = elContainer.children[0];
	return firstChildEl && firstChildEl.classList.contains('contentLayout2');
};

/**
 * Get current scroll position data so that we can restore it in edit page or view page.
 * This function can be used in view page or inside editor content iframe.
 * @param elContainer - DOM element which contains page content
 * @param scrollY - it's window.scrollY or window.pageYOffset.
 * If this function is used inside editor content iframe, scrollY should be given with right context.
 * @returns {*}
 */
export function getRelativeScrollPosition(
	elContainer: HTMLElement | null,
	scrollY: number,
): ViewPositionData | null {
	if (scrollY === 0 || elContainer === null) {
		return null;
	}

	const viewPositionData: ViewPositionData = {
		// index of block element in page, we call it as target element
		targetElementIndex: -1,
		// number of pixels from top of target element to current scroll position
		offsetBetweenTargetAndCurrentScroll: 0,
		// in case page has column layout, we need to save extra info.
		columnLayoutPosition: {
			columnIndex: -1,
			rowIndex: -1,
		},
	};

	if (!hasColumnLayout(elContainer)) {
		// easy in case page does not have column layout
		const { targetElementIndex, visibleBlockEl } = findTargetElement(elContainer.children);

		if (visibleBlockEl) {
			viewPositionData.targetElementIndex = targetElementIndex;
			viewPositionData.offsetBetweenTargetAndCurrentScroll =
				scrollY - getElementTop(visibleBlockEl);

			// don't need column layout data
			delete viewPositionData.columnLayoutPosition;
		}
	} else {
		// page has column layout so page content starts from first element
		const startPageContent = elContainer.children[0];
		const rows = startPageContent.children;

		// find visible row.
		const { targetElementIndex: rowIndex, visibleBlockEl: visibleRow } = findTargetElement(rows);
		if (visibleRow && visibleRow.getElementsByClassName) {
			const columns = visibleRow.getElementsByClassName('innerCell');
			const {
				columnIndex,
				targetElementIndexInColumn,
				visibleBlockEl: visibleBlockInColumn,
			} = findVisibleColumn(columns);

			if (visibleBlockInColumn) {
				viewPositionData.targetElementIndex = targetElementIndexInColumn;
				viewPositionData.offsetBetweenTargetAndCurrentScroll =
					scrollY - getElementTop(visibleBlockInColumn);
				viewPositionData.columnLayoutPosition = { rowIndex, columnIndex };
			}
		}
	}

	return viewPositionData.targetElementIndex !== -1 ? viewPositionData : null;
}

/**
 * Save scroll position in session storage temporarily so that we can restore the scroll position in next page (view page or edit page).
 * @param {Object} options
 * @param {String} options.contentId - content id
 * @param {Boolean} options.isInViewPage
 * @param {Object} options.documentObject
 * @param {Object} options.winObject
 * @param {string} options.storageKeySuffix
 */
export const persistRelativeScrollPosition = (options: ScrollOptions): void => {
	const {
		contentId,
		isInViewPage,
		docObject = document,
		winObject = window,
		storageKeySuffix = '',
	} = options;
	let mainContentEl: HTMLElement | null;
	if (isInViewPage) {
		// in view page
		mainContentEl = docObject.getElementById(keys.ID_START_CONTENT_IN_VIEW_PAGE);
	} else {
		// in edit page
		mainContentEl = docObject.body;
	}

	const scrollPosition = getRelativeScrollPosition(mainContentEl, winObject.pageYOffset);
	const persistScrollKey = keys.PERSIST_SCROLL_POSITION_IN_VIEW_PAGE + contentId + storageKeySuffix;

	if (scrollPosition) {
		// add extra information so that when we do restoring, the data is safer
		scrollPosition.contentId = contentId;
		scrollPosition.isInViewPage = isInViewPage;
		sessionStorage.setItem(persistScrollKey, JSON.stringify(scrollPosition));
	} else {
		// when the scroll position is at the very top (default)
		// the scrollPosition is null, and we must check if there is a persisted value to avoid
		// the page from jumping
		const storedPosition = sessionStorage.getItem(persistScrollKey);
		if (storedPosition) {
			sessionStorage.removeItem(persistScrollKey);
		}
	}
};

/**
 * Restore scroll position basing saved position data in session storage
 * @param {Object} options
 * @param {String} options.contentId - content id
 * @param {Boolean} options.isInViewPage
 * @param {Object} options.docObject
 * @param {Object} options.winObject - window object is used to do scrolling.
 * @param {string} options.hash
 * @param {string} options.storageKeySuffix
 */
export const restoreScrollPositionIfNeeded = (options) => {
	const {
		contentId,
		isInViewPage,
		contentReadyState = 0,
		docObject = document,
		winObject = window,
		hash,
		storageKeySuffix = '',
	} = options;
	const persistScrollKey = keys.PERSIST_SCROLL_POSITION_IN_VIEW_PAGE + contentId + storageKeySuffix;
	let savedPosition = sessionStorage.getItem(persistScrollKey);

	try {
		savedPosition = JSON.parse(savedPosition);
		if (hash) {
			const decodeHash = decodeURIComponent(hash);
			const el = docObject.getElementById(decodeHash);
			const scrollPosition = el?.getBoundingClientRect().top;
			scrollPosition && winObject.scrollTo({ top: scrollPosition });
		}
	} catch (error) {
		// could not parse the savedPosition or
		// anchor is invalid, may contain UTF-8 encoding of emoji which does not nice map back to the querySelector
		getMonitoringClient().submitError(error, { attribution: 'backbone' });
	} finally {
		// removing the persisted scroll position here would cause the scroll position to jump
		// since this function should get called when content is and is not ready during SSR to SPA.
		// ideally we will use local storage values on SSR
		// however, when the content is READY (1) there is no need to persist the position
		if (contentReadyState === 1) {
			sessionStorage.removeItem(persistScrollKey);
		}
	}

	if (
		hash ||
		// hash exists in the url, window already scrolled to the element in view
		!savedPosition ||
		// does not match contentId so this data is invalid
		savedPosition.contentId !== contentId
	) {
		return;
	}

	let contentWrapper;

	if (isInViewPage) {
		contentWrapper = docObject.getElementById(keys.ID_START_CONTENT_IN_VIEW_PAGE);
	} else {
		// inside editor iframe, content starts from body tag
		contentWrapper = docObject.body;
	}

	if (!contentWrapper || !contentWrapper.children[0]) {
		return;
	}

	const { columnLayoutPosition } = savedPosition;
	let targetElement: HTMLElement | undefined;

	if (columnLayoutPosition) {
		// page have layout
		const startEditorContent: HTMLElement = contentWrapper.children[0];
		const targetRow = startEditorContent.children[columnLayoutPosition.rowIndex] as HTMLElement;
		const columns = targetRow?.getElementsByClassName('innerCell');
		const targetColumn = columns?.[columnLayoutPosition.columnIndex];
		targetElement =
			targetColumn && (targetColumn?.children?.[savedPosition.targetElementIndex] as HTMLElement);
	} else {
		// page does not have layout
		targetElement = contentWrapper.children[savedPosition.targetElementIndex];
	}

	if (targetElement) {
		const topOffset = getElementTop(targetElement);
		const offset = savedPosition.offsetBetweenTargetAndCurrentScroll;
		let newPosition = topOffset + offset;
		// start scrolling to avoid jumping to the top of the page.
		if (isInViewPage) {
			const maxScrollHeight = docObject?.querySelector?.('#main-content')?.clientHeight || 0;
			newPosition = Math.min(maxScrollHeight, newPosition);
		}
		winObject.scrollTo(0, newPosition);
	}
};
