import { Cache } from '@confluence/generics';

import type { EditPermissionResolution, IHeuristicsCache } from './types';

/**
 * Heuristics Cache
 *
 * With each new page comes the potential for a lookup to the
 * apollo cache and session data promise to resolve the likelihood
 * that the current user may edit the page they're viewing. This
 * cache provides O(1) lookups to contentId's that may have
 * already been evaluated against the current user's likelihood
 * to edit
 */
export class HeuristicsCache {
	protected static Cache = new Cache<IHeuristicsCache>({
		tendencyMap: new Cache<Record<string, boolean>>({}),
		permissionMap: new Cache<Record<string, EditPermissionResolution>>({}),
	});

	/**
	 * With Cache
	 *
	 * Wraps a heuristic with Cached retrievals and storage
	 */
	protected static withCache<
		K extends keyof IHeuristicsCache,
		T extends (contentId: string) => any,
	>(index: K, callback: T): (contentId: string) => ReturnType<T> {
		return (contentId: string) => {
			const cache = this.Cache.get(index);
			if (cache.has(contentId)) {
				return cache.get(contentId);
			}
			const value = callback(contentId);
			if (value instanceof Promise) {
				return value.then((v) => {
					cache.set(contentId, v);
					return v;
				});
			}
			cache.set(contentId, value);
			return value;
		};
	}

	/**
	 * Reset
	 *
	 * Resets all cached values
	 */
	public static reset() {
		this.Cache.keys.forEach((key) => {
			this.Cache.get(key).clear();
		});
	}
}
