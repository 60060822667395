import type { DocumentNode } from 'graphql';
import { useMemo } from 'react';
import { useApolloClient } from 'react-apollo';

import { fg } from '@confluence/feature-gating';

import { isLivePagesFeatureEnabled } from './isLivePagesFeatureEnabled';
import {
	ContentSubTypeAndStatusFragment,
	PTPageSubTypeFragment,
} from './graphql/SubTypeAndStatus.graphql';

const FRAGMENTS_BY_TYPE: { gqlType: string; fragment: DocumentNode }[] = [
	{
		gqlType: 'Content',
		fragment: ContentSubTypeAndStatusFragment,
	},
	{
		gqlType: 'PTPage',
		fragment: PTPageSubTypeFragment,
	},
];

/* Retrieves subtype and status by contentId from Apollo cache by looking at types returned by multiple queries.
 * Why each GQL type is used:
 * PTPage is used by: Pages retrieved by Page Tree
 * Content is used by: Current page, pages retrieved by Recents (Worked on/Created by me/etc), and pages retrieved by Home's Discover feed (Following/Popular/etc)
 */
export const useGetCachedPageData = (contentId: string) => {
	const isLivePageFeatureEnabled = isLivePagesFeatureEnabled();
	const client = useApolloClient();

	return useMemo((): { subType?: string; isArchived?: boolean } | undefined => {
		if (!isLivePageFeatureEnabled || !fg('confluence_frontend_livepages_fastloading')) {
			return { subType: undefined, isArchived: undefined };
		}

		let subType: string | undefined;
		let isArchived: boolean | undefined = undefined;
		FRAGMENTS_BY_TYPE.some(({ gqlType, fragment }) => {
			try {
				// readFragment throws an error if it finds the item in the cache without both id and subtype, so we wrap in try/catch
				const fragmentData = client.readFragment({
					id: `${gqlType}:${contentId}`,
					fragment,
				});
				if (fragmentData?.subType) {
					subType = fragmentData.subType;
					isArchived = fragmentData.status?.toLowerCase?.() === 'archived';
					return true;
				}
			} catch {}
		});
		return { subType, isArchived };
	}, [contentId, isLivePageFeatureEnabled, client]);
};
