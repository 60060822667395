import gql from 'graphql-tag';

export const ContentSubTypeAndStatusFragment = gql`
	fragment SubTypeAndStatusContentFragment on Content {
		id
		subType
		status
	}
`;

// Page tree does not contain archived pages and PTPage `status` does not contain an `archived` status, so we don't need it here.
export const PTPageSubTypeFragment = gql`
	fragment SubTypePageTreeFragment on PTPage {
		id
		subType
	}
`;
