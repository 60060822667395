import { EMBEDDED_CONFLUENCE_MODE, EMBEDDED_CONFLUENCE_CONTENT_TYPE } from './page-common';

export enum ALLOWED_FEATURES_TYPE {
	VIEW = 'view',
	EDIT = 'edit',
	WHITEBOARD = 'whiteboard',
}

export type AllowedFeatures<T> = T extends ALLOWED_FEATURES_TYPE.VIEW | 'view'
	? Partial<(typeof ALLOWED_FEATURES.view)[number]>[] | 'all'
	: T extends ALLOWED_FEATURES_TYPE.EDIT | 'edit'
		?
				| Partial<
						| (typeof ALLOWED_FEATURES.edit)[number]
						| { 'template-browser': { 'space-picker-disabled': boolean } }
				  >[]
				| 'all'
		: T extends ALLOWED_FEATURES_TYPE.WHITEBOARD | 'whiteboard'
			? Partial<(typeof ALLOWED_FEATURES.whiteboard)[number]>[] | 'all'
			: never;

export type AnyAllowedFeatures =
	| AllowedFeatures<ALLOWED_FEATURES_TYPE.VIEW>
	| AllowedFeatures<ALLOWED_FEATURES_TYPE.EDIT>
	| AllowedFeatures<ALLOWED_FEATURES_TYPE.WHITEBOARD>;

export type PageAllowedFeatures = {
	view?: Partial<Exclude<(typeof ALLOWED_FEATURES.view)[number], 'edit'>>[] | 'all';
	edit?: Partial<(typeof ALLOWED_FEATURES.edit)[number]>[] | 'all';
};

export type ParsedPageAllowedFeatures = {
	view?: Partial<(typeof ALLOWED_FEATURES.view)[number]>[] | 'all';
	edit?: Partial<(typeof ALLOWED_FEATURES.edit)[number]>[] | 'all';
};

// Existing features parent product can enable/disable
export const ALLOWED_FEATURES = {
	// Since view and edit is a public API already, changing this would break parent products
	// hence we could update it separately as a breaking change later. With more content types which could
	// have edit, view, the suggested patter to follow: <contentType>-<mode> e.g., database-view
	view: [
		'byline-contributors',
		'byline-extensions',
		'delete',
		'edit',
		'inline-comments',
		'page-comments',
		'page-reactions',
		'sticky-header',
		'disable-login-flow',
		'disable-share',
		'non-licensed-share',
	],
	edit: ['delete-draft', 'disable-login-flow', 'template-browser', 'inline-comments'],
	whiteboard: ['disable-login-flow', 'copy', 'favorite', 'restrictions', 'more-actions'],
};

export const DEFAULT_ALLOWED_FEATURES: {
	view: Exclude<AllowedFeatures<ALLOWED_FEATURES_TYPE.VIEW>, 'all'>;
	edit: Exclude<AllowedFeatures<ALLOWED_FEATURES_TYPE.EDIT>, 'all'>;
	whiteboard: Exclude<AllowedFeatures<ALLOWED_FEATURES_TYPE.WHITEBOARD>, 'all'>;
} = {
	view: [
		'byline-contributors',
		'byline-extensions',
		'page-comments',
		'page-reactions',
		'inline-comments',
	],
	edit: ['inline-comments'],
	whiteboard: ['copy', 'favorite', 'restrictions', 'more-actions'],
};

const pageAllowedFeatures: Record<EMBEDDED_CONFLUENCE_MODE, string[]> = {
	[EMBEDDED_CONFLUENCE_MODE.EDIT_MODE]: ALLOWED_FEATURES.edit,
	[EMBEDDED_CONFLUENCE_MODE.VIEW_MODE]: ALLOWED_FEATURES.view,
};

const blogPostAllowedFeatures: Record<EMBEDDED_CONFLUENCE_MODE, string[]> = {
	[EMBEDDED_CONFLUENCE_MODE.EDIT_MODE]: ALLOWED_FEATURES.edit,
	[EMBEDDED_CONFLUENCE_MODE.VIEW_MODE]: ALLOWED_FEATURES.view,
};

const whiteboardAllowedFeatures: Record<EMBEDDED_CONFLUENCE_MODE, string[]> = {
	[EMBEDDED_CONFLUENCE_MODE.EDIT_MODE]: ALLOWED_FEATURES.whiteboard,
	[EMBEDDED_CONFLUENCE_MODE.VIEW_MODE]: ALLOWED_FEATURES.whiteboard,
};

const contentTypeAllowedFeatures: Record<
	EMBEDDED_CONFLUENCE_CONTENT_TYPE,
	Record<EMBEDDED_CONFLUENCE_MODE, string[]>
> = {
	[EMBEDDED_CONFLUENCE_CONTENT_TYPE.PAGE]: pageAllowedFeatures,
	[EMBEDDED_CONFLUENCE_CONTENT_TYPE.BLOGPOST]: blogPostAllowedFeatures,
	[EMBEDDED_CONFLUENCE_CONTENT_TYPE.WHITEBOARD]: whiteboardAllowedFeatures,
};

export const getAllowedFeatures = ({
	contentType,
	mode = EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
}: {
	contentType: EMBEDDED_CONFLUENCE_CONTENT_TYPE;
	mode?: EMBEDDED_CONFLUENCE_MODE;
}): AnyAllowedFeatures => contentTypeAllowedFeatures[contentType]?.[mode] ?? [];
