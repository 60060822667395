import gql from 'graphql-tag';

import { ContentOperationsFragment } from '@confluence/entity-operations/entry-points/EntityOperationsFragment.fragment';

/**
 * Query used to determine if editor resources should be preloaded on the view page
 * Including a edit operation permission check and embedded property check to determine correct resources
 */
export const ViewPageEditorPreloadQuery = gql`
	query ViewPageEditorPreloadQuery($contentId: ID) {
		content(id: $contentId) {
			nodes {
				...ContentOperationsFragment
				metadata {
					frontend {
						embedded
					}
				}
			}
		}
	}
	${ContentOperationsFragment}
`;
