import { useContext, useEffect, useRef } from 'react';

import {
	persistRelativeScrollPosition,
	restoreScrollPositionIfNeeded,
} from '@confluence/persistent-scroll-position';
import { RoutesContext } from '@confluence/route-manager';

const useUnload = (fn: () => any) => {
	useEffect(() => {
		window.addEventListener('beforeunload', fn);
		return () => {
			window.removeEventListener('beforeunload', fn);
		};
	}, [fn]);
};

export function useViewPagePersistentScrollPosition(contentId: string, storageKeySuffix?: string) {
	const { getHash } = useContext(RoutesContext);

	const hashRef = useRef(getHash);

	// Classnames generated from compiled change between SSR and SPA
	// this leads to the page scrolling to the top of the DOM during that transition
	// this fix captures the scroll position right before unload/refresh of the page
	// Upon initial load, the window will attempt to access and scroll to the previously saved position
	// if the contentId matches
	useEffect(() => {
		restoreScrollPositionIfNeeded({
			contentId,
			isInViewPage: true,
			contentReadyState: 1,
			docObject: document,
			winObject: window,
			hash: hashRef.current(),
			storageKeySuffix,
		});
	}, [contentId, storageKeySuffix]);

	useUnload(() => {
		persistRelativeScrollPosition({
			contentId,
			isInViewPage: true,
			docObject: document,
			winObject: window,
			storageKeySuffix,
		});
	});
}
