import React from 'react';

import { usePageContentId } from '@confluence/page-context';

import { LegacyBridgeNextInner } from './LegacyBridgeNextInner';

export function LegacyBridgeNext() {
	const [contentId] = usePageContentId();

	return contentId ? <LegacyBridgeNextInner contentId={contentId} /> : null;
}
