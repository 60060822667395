import { parseUrl } from '@atlassian/embedded-confluence';

export enum ProjectNumber {
	staging = '1017113233545',
	production = '118174936708',
}

export enum Hostname {
	staging = 'cc-embedded-pages-integ-ui.stg-east.frontend.public.atl-paas.net',
	production = 'cc-embedded-pages-integ.atlassian.com',
}

// Exported for testing
export const buildMainStageUrl = (hostname: Hostname, queryParams: string): string =>
	`https://${hostname}/google-meet/page?${queryParams}`;

// Exported for testing
export const buildQueryParams = (href: string): string => {
	const buildUrl = `${window.location.origin}${href}`;
	const parsedURL = parseUrl(buildUrl) ?? {};
	return new URLSearchParams(parsedURL).toString();
};

export const exposeToMeetWhenScreensharing = (href: string): void => {
	const currentEnv = process.env.NODE_ENV as string;
	// Create the correct query params for the main stage url - based on confluence embedded page
	const queryParams = buildQueryParams(href);

	// Grab hardcoded hostname and project number based on current env
	const hostname = Hostname[currentEnv] ?? Hostname.staging;
	const cloudProjectNumber = ProjectNumber[currentEnv] ?? ProjectNumber.staging;

	// Fire the Google add-on method to allow promotion and add-on use when screensharing
	// startActivityOnOpen needs to be set to true as long as we use mainstageUrl
	// See docs for additional info: https://developers.google.com/meet/add-ons/reference/websdk/screenshare_api.addonscreenshareinfo.startactivityonopen
	window?.meet?.addon?.screensharing?.exposeToMeetWhenScreensharing({
		cloudProjectNumber,
		startActivityOnOpen: true,
		mainStageUrl: buildMainStageUrl(hostname, queryParams),
	});
};
